<template>
  <t-dialog
    :close-button-text="closeButtonText"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <h2 class="phone-dialog__title">
      {{ title }}
    </h2>
    <img
      v-if="imageSrc"
      class="phone-dialog__image"
      :src="imageSrc"
      :alt="title"
    />
    <p class="phone-dialog__text">
      {{ text }}
    </p>
  </t-dialog>
</template>

<script>
export default {
  name: 'WhyPhoneDialog',

  props: {
    closeButtonText: {
      type: String,
      default: 'Verder met je aanvraag',
    },

    title: {
      type: String,
      default: '',
    },

    text: {
      type: String,
      default: '',
    },

    imageSrc: {
      type: String,
      default: '',
    },
  },
}
</script>
