<template>
  <section>
    <h1 class="form-part__title mb-6">
      {{ title }}
    </h1>

    <t-form ref="form" @submit="onSubmitButton" @submit.prevent>
      <NameField :autofocus="true" @result="onResult" @change="onChange" />
      <EmailField @result="onResult" @change="onChange" />
      <AddressFormGroup
        :init-with-postal="true"
        @result="onResult"
        @change="onChange"
      />
      <PhoneFormPart @result="onResult" @change="onChange" />
      <FormStepSubmitButton
        id="submitContactInformation"
        class="contact-information-button"
        text="Ontvang offertes"
        type="button"
        @click="onSubmitButton"
      />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep.vue'
import NameField from 'chimera/all/components/form/fields/name/NameField'
import EmailField from 'chimera/all/components/form/fields/email/EmailField'
import PhoneFormPart from 'chimera/officeOfNotary/components/form/parts/phone/PhoneFormPart'
import AddressFormGroup from 'chimera/all/components/form/parts/address/AddressFormGroup'
import LeadSubmitPage from '~/pages/offertes-aanvragen/versturen'

export default {
  name: 'ContactInformationFormStep',

  components: {
    NameField,
    EmailField,
    AddressFormGroup,
    PhoneFormPart,
  },

  extends: AbstractFormStep,

  /**
   * Component state
   *
   * @returns {object}
   */
  data() {
    return {
      title: 'Hoe kunnen de notarissen je bereiken?',
    }
  },

  methods: {
    /**
     */
    onSubmitButton() {
      this.onSubmit()
    },

    /**
     * Transition
     */
    transition() {
      this.routeTo(LeadSubmitPage)
    },
  },
}
</script>
