<template>
  <div class="phone-form-part">
    <div class="phone-form-part__column">
      <PhoneField v-on="$listeners" />
    </div>
    <div class="phone-form-part__column">
      <span
        id="openDialog"
        v-track-click
        class="phone-form-part__open-dialog-btn"
        @click.stop="showModal = true"
      >
        Waarom mijn <br />
        telefoonnummer?
      </span>

      <WhyPhoneDialog
        v-model="showModal"
        :title="dialogTitle"
        :text="dialogText"
        :image-src="dialogImageSrc"
      />
    </div>
  </div>
</template>

<script>
import WhyPhoneDialog from 'chimera/all/components/dialog/WhyPhoneDialog'
import PhoneField from 'chimera/all/components/form/fields/phone/PhoneField'

export default {
  name: 'PhoneFormPart',

  components: {
    PhoneField,
    WhyPhoneDialog,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      showModal: false,
      dialogTitle: 'Waarom willen jullie mijn telefoonnummer hebben?',
      dialogText:
        'Onze notarissen maken speciaal voor jou geheel vrijblijvend een offerte op maat, zo weet je tot in detail wat je kunt verwachten zonder dat je kosten maakt. Hierdoor kan je onze notarissen makkelijk met elkaar vergelijken. Indien ze nog vragen hebben dan kunnen onze notarissen eventueel zo contact met je opnemen.',
      dialogImageSrc: 'images/phone-dialog.png',
    }
  },
}
</script>
